import { Link } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"

import Accordion, { AccordionSection } from "$components/Accordion"
import Banner from "$components/Banner"
import Container from "$components/Container"
import Feature from "$components/Feature"
import Footer from "$components/Footer"
import Header from "$components/Header"
import SEO from "$components/SEO"

import bannerServices from "./assets/banner-services.jpg"
import iconGears from "./assets/icon-gears.svg"
import iconMonitor from "./assets/icon-monitor.svg"
import iconShield from "./assets/icon-shield.svg"

const AccordionH3 = styled.h3`
  font-weight: 400;
  margin: 1rem 0;
  text-align: left;
`

const accordionStyles = css`
  margin: 0 auto;
`

const BannerContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const ContactBanner = styled.div`
  background-color: ${props => props.theme.alto};
  line-height: 2;
  padding: 1rem 0;
`

const ContactBannerText = styled.p`
  color: ${props => props.theme.nero};
  text-align: center;
`

const ContactButton = styled.button.attrs(() => ({ as: Link }))`
  background-color: transparent;
  border: solid 1px ${props => props.theme.tundora};
  color: inherit;
  display: inline-block;
  font-family: ${props => props.theme.fontPrimary};
  font-size: inherit;
  margin-left: 16px;
  outline: none;
  padding: 8px 24px;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s;
  width: max-content;

  :hover {
    background-color: ${props => props.theme.tundora};
    color: white;
  }

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    display: block;
    margin: 8px auto;
  }
`

// const H2 = styled.h2`
//   font-size: 1.2rem;
//   font-weight: 400;
// `

// const Table = styled.table`
//   border: 1px solid black;
//   border-collapse: collapse;
//   font-family: ${props => props.theme.fontSecondary};
//   font-size: 1.1rem;
//   margin: 0 auto;

//   tbody td {
//     border-right: 1px solid black;
//   }

//   td {
//     padding: 6px 18px 6px 12px;
//   }

//   thead td {
//     border: 1px solid black;
//   }
// `

const Ul = styled.ul`
  font-family: ${props => props.theme.fontSecondary};
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 1rem auto;
  width: 60%;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    padding-left: 24px;
    width: 90%;
  }

  & ul {
    width: 80%;
  }
`

const accordionData = [
  {
    content: (
      <>
        <p>
          We support both physical and cloud infrastructure. We have helped many
          businesses migrate from physical hardware to the cloud, and also from
          cloud services back to physical. Many organisations can have their own
          server hardware housed remotely.
        </p>
        <p>
          LOTUS is able to remotely manage your server by installing a secure
          agent to provide our staff with real-time telemetry of server
          resources. This allows us to proactively manage your practice's main
          IT asset, with alerts automatically generating helpdesk tickets for us
          to investigate, manage and resolve.
        </p>
        <p>
          This support option provides a capped monthly charge to support server
          infrastructure and services, including:
        </p>
        <Ul>
          <li>Server hardware</li>
          <li>Microsoft Windows Server operating system</li>
          <li>Microsoft Exchange Server</li>
          <li>Microsoft SQL Server</li>
          <li>Microsoft Domain Controller</li>
          <li>Active directory management</li>
          <li>User and object management</li>
          <li>Print server management</li>
          <li>DHCP and internal DNS management</li>
          <li>Server patching and OS update management</li>
          <li>Server antivirus software protection</li>
          <li>Server configuration and monitoring</li>
          <li>LAN switching equipment</li>
        </Ul>
      </>
    ),
    heading: (
      <AccordionH3>
        Server Systems and Local Area Network (LAN) Support
      </AccordionH3>
    ),
    id: "server-systems-lan-support",
  },
  {
    content: (
      <>
        <p>
          We provide your practice with support for all of your software
          applications, as well as users' local desktop environments. These
          environments are managed centrally by LOTUS's systems located on your
          premises.
        </p>
        <p>
          Staff using their own computing environments, such as as a result of
          the coronavirus, adds a layer of complexity but we do offer the option
          to support working from home.
        </p>
        <p>
          This support option provides a capped monthly charge to support local
          user environments, including:
        </p>
        <Ul>
          <li>
            User desktop hardware - notebooks, workstations and/or thin clients
          </li>
          <li>
            Operating systems - including but not limited to Microsoft Windows,
            macOS
          </li>
          <li>Microsoft Office suite</li>
          <li>Antivirus management</li>
          <li>Adobe, Java frameworks</li>
          <li>Browsers</li>
          <li>
            Practice management software such as:
            <ul>
              <li>
                MYOB Accountants Office (AO) and Accountants Enterprise (AE)
              </li>
              <li>ReckonAPS</li>
              <li>Sage Handisoft</li>
              <li>LEAP Legal Software</li>
            </ul>
          </li>
          <li>
            Other practice software including:
            <ul>
              <li>Virtual Cabinet</li>
              <li>Castaway</li>
              <li>Agrimaster</li>
              <li>Phoenix</li>
              <li>STM</li>
              <li>SimpleFund</li>
              <li>CashMan, ShareMan, LeaseMan, FinMan</li>
              <li>BankLink</li>
              <li>pdfDocs</li>
              <li>MYOB Accountant applications</li>
              <li>Reckon Accountant applications</li>
            </ul>
          </li>
        </Ul>
      </>
    ),
    heading: <AccordionH3>Application and User Desktop Support</AccordionH3>,
    id: "application-user-desktop-support",
  },
  {
    content: (
      <>
        <p>
          Every practice has a router, but have you configured your practice's
          router correctly? Having the right device as your gateway to the
          internet can stop malicious code at the practice door. The standard
          modem/router combo provided by most telecommunications providers can
          be serviceable for your home network but will leave businesses wanting
          more advanced features such as gateway antivirus and spam filters.
        </p>
        <p>
          With this support option LOTUS will manage your router, wireless
          access points, firewall and gateway security, as well as liaise with
          your internet service provider on your behalf.
        </p>
        <Ul>
          <li>Router hardware and firmware management</li>
          <li>UTM configuration and management</li>
          <li>Firewall configuration and management</li>
          <li>Wireless access point configuration and management</li>
          <li>Router and connection monitoring</li>
          <li>
            Liaising with your telecommunications provider to resolve issues
          </li>
        </Ul>
      </>
    ),
    heading: (
      <AccordionH3>Router, Wireless and Internet Provider Support</AccordionH3>
    ),
    id: "router-wireless-internet-provider",
  },
  {
    content: (
      <>
        <p>
          After evaluating many of the backup systems on the market, in 2010 we
          discovered a relatively new player in the backup space at the time
          called{" "}
          <a
            href="https://www.veeam.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Veeam
          </a>
          . Their expertise in the field of virtualisation, together with their
          aggressive pricing model, has made them an industry leader in backup.
        </p>
        <p>
          A proud authorised Veeam reseller, LOTUS provides clients with a fully
          managed cloud backup solution, with all cloud systems hosted in
          Australia.
        </p>
        <p>
          We install either a physical or virtual local backup repository on
          your premises to optimise backups.
        </p>
      </>
    ),
    heading: <AccordionH3>Backup Systems</AccordionH3>,
    id: "backup-systems",
  },
  {
    content: (
      <>
        <p>
          Managing and hosting your domain/s and website can be confusing. If
          terms such as MX entries are foreign to you, then let LOTUS handle it.
        </p>
        <p>
          Our domain and website services start at $22/month which includes
          domain name registration and unlimited changes to meet your needs.
        </p>
        <p>
          A relatively new service we now provide is website development. We
          have noticed that some of our clients have been overcharged for
          development of simple websites, and found it difficult to request
          changes from their developer. We now have a team to develop and
          maintain your practice's website.
        </p>
        <p>
          An informative, well-designed website can be an essential part of your
          business's strategy. LOTUS can help you develop and maintain a
          professional website to fit your requirements. We use a range of
          technologies suited for different timeframes and budgets.
        </p>
        <Ul>
          <li>
            WordPress, a template-based system, allows us to quickly create
            easily maintainable, attractive websites with vast available
            functionality.
          </li>
          <li>
            For greater customisation, we build your website in-house to create
            personalised, powerful, adaptable interfaces. LOTUS's own website is
            built in-house and continually updated and refined.
          </li>
        </Ul>
        <p>
          LOTUS's web development team, along with all of our staff, are based
          locally in Sydney and you can expect the same level of personalised,
          on-demand support.
        </p>
      </>
    ),
    heading: <AccordionH3>Domain and Website Services</AccordionH3>,
    id: "domain-website-services",
  },
  {
    content: (
      <>
        <p>
          In the past specific telephone cables were required for voice calls,
          but today we are able to use our existing internet connections for
          voice calls. This ability is referred to as Voice over Internet
          Protocol (VoIP) and all NBN implementations use VoIP for telephony.
        </p>
        <p>
          The VoIP system we recommend to our clients allows each staff member
          to take their desk phone home and have it operate as if they were in
          the office.
        </p>
        <p>
          LOTUS is proud to work with{" "}
          <a
            href="https://smartbill.com.au"
            rel="noopener noreferrer"
            target="_blank"
          >
            Smartbill
          </a>{" "}
          to provide our clients with services such as Mobile Expense Management
          and Asset & Invoice Management, services that have previously only
          been available to much larger corporate clients at higher prices.
        </p>
      </>
    ),
    heading: (
      <AccordionH3>Voice, Mobile and Mobile Device Management</AccordionH3>
    ),
    id: "voice-mobile-device-management",
  },
  {
    content: (
      <>
        <p>
          We value the support of printing, copying and imaging device
          manufacturer{" "}
          <a
            href="https://www.kyoceradocumentsolutions.com.au/default.aspx"
            rel="noopener noreferrer"
            target="_blank"
          >
            Kyocera
          </a>
          . LOTUS is proud to be a Premium Partner with Kyocera who have proven
          to be very dependable for after-sales support, allowing us to ensure
          continued service to our clients.
        </p>
        <p>
          We are not limited to monitoring and management of Kyocera equipment,
          but are happy to work with any other device manufacturers your
          organisation has already implemented.
        </p>
      </>
    ),
    heading: (
      <AccordionH3>Printing, Copying and Imaging Device Management</AccordionH3>
    ),
    id: "printing-copying-imaging-device-management",
  },
]

const featureData = [
  {
    heading: "Managed Services",
    imgAlt: "Gear icon",
    imgSrc: iconGears,
    linkTo: "/services/#managed-services",
    text:
      "Let us manage your IT needs, whether it's hardware, software or networking.",
  },
  {
    heading: "Procurement Services",
    imgAlt: "Monitor icon",
    imgSrc: iconMonitor,
    linkTo: "/services/#procurement-services",
    text:
      "We work with our suppliers to provide you with equipment for your business's office or home office.",
  },
  {
    heading: "Projects and Consulting",
    imgAlt: "Shield icon",
    imgSrc: iconShield,
    linkTo: "/services/#projects-consulting",
    text:
      "We can work with you for any projects including one-off events and any special requirements.",
  },
]

const title = "Professional IT Services | Lotus Network Solutions"
const description =
  "We offer managed IT, procurement, security services and more, to keep your business running."

export default () => (
  <>
    <SEO description={description} title={title} />
    <Header />
    <main>
      <Banner bannerImage={bannerServices} height="400">
        <BannerContainer>
          <h1>Services</h1>
        </BannerContainer>
      </Banner>
      <section>
        <Feature data={featureData} />
      </section>
      <section>
        <Container>
          <h2 id="managed-services">Managed Services</h2>
          <p>
            LOTUS can manage all of your business's requirements for information
            technology infrastructure and support. We offer both the flexibility
            to work with practices to align their existing IT environments with
            industry best practices, as well as the expertise to design,
            implement and manage your complete IT environment from the ground
            up.
          </p>
          <p>
            <strong>Capped monthly cost guarantee: </strong>Seemingly small
            issues in IT can take considerable time to resolve, and nobody likes
            to receive a large surprise invoice. We work to provide the best
            value for your investment to manage your IT. Whenever we provide a
            quote for services, the cost is always capped at our quoted price.
          </p>
          <p>
            <strong>Adaptable: </strong>Our managed services can grow with your
            business and adapt to your needs. LOTUS is able to help with one-off
            or urgent queries such as completing cyber insurance forms or
            assisting staff to work from home.
          </p>
          <Accordion wrapperStyles={accordionStyles}>
            {accordionData.map(props => (
              <AccordionSection {...props} />
            ))}
          </Accordion>
        </Container>
      </section>
      <section>
        <Container>
          <h2 id="procurement-services">Procurement Services</h2>
          <p>
            LOTUS provides hardware, software and cloud offerings such as
            Microsoft Office 365 and public & private cloud services to
            practices throughout Australia. Our team is continually assessing
            products from different manufacturers for functionality, innovation,
            price and after-sales support to provide the the best solutions with
            the best value for our clients. We find the manufacturers and
            providers that best fit your needs.
          </p>
          <p>
            LOTUS will work with your practice to provide end-to-end delivery,
            management and eventual decommissioning of hardware, software and
            services.
          </p>
          <p>
            LOTUS is able to purchase hardware for your business and staff. From
            computers to servers to networking equipment, we work with{" "}
            <Link to="/about/#vendor-certification">our partners</Link> to
            provide a wide range of hardware at competitive prices. Purchasing
            does not need to be combined with our support services, but offering
            them together allows us to design and support IT solutions for the
            entire lifecycle of the hardware.
          </p>
          {/* <h3>Clearance Items</h3>
        <p>
          From time to time LOTUS has excess stock of peripherals and
          accessories such as mice, keyboards and cables. All items are brand
          new unless listed otherwise. Below are listed some of our current
          stock; please inquire for purchase.
        </p>
        <Table>
          <thead>
            <tr>
              <td>Item</td>
              <td>Price</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Keyboard</td>
              <td>$20</td>
            </tr>
            <tr>
              <td>Mouse</td>
              <td>$10</td>
            </tr>
            <tr>
              <td>24" Monitor</td>
              <td>$150</td>
            </tr>
            <tr>
              <td>HDMI to HDMI cable</td>
              <td>$20</td>
            </tr>
          </tbody>
        </Table> */}
        </Container>
      </section>
      <section>
        <Container>
          <h2 id="projects-consulting">Projects and Consulting</h2>
          <p>
            We are able to consult and work on projects large and small. Our
            experience in the industry spans over 30 years primarily in the
            finance and legal sectors, both in Australia and overseas, and we
            have worked for a diverse range of clients in finance, education and
            event management. We thrive in the variety and the challenge of
            project work, consistently exceeding expectations of stakeholders.
            Our decades of knowledge and experience translate into solutions
            that are efficient, reliable, manageable and within budget.
          </p>
          <p>
            We hold a diverse range of accreditations and certifications in
            information technology. With the rapid advancement in today's
            industry, we have partners in the areas of technology most relevant
            to the businesses we support. Our experience as technology providers
            informs us when to implement cutting-edge technology and when to
            rely on the tried-and-true. We implement industry best practices by
            providing specialist staff to find the best, vendor-agnostic
            solutions for the project at hand.
          </p>
        </Container>
      </section>
      <ContactBanner>
        <ContactBannerText>
          See how LOTUS can find solutions for your business today.
          <ContactButton to="/contact/">Contact us</ContactButton>
        </ContactBannerText>
      </ContactBanner>
    </main>
    <Footer />
  </>
)
