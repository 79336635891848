import { useLocation } from "@reach/router"
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import styled from "styled-components"

import arrowIcon from "$assets/arrow-icon.svg"

const AccordionContent = styled.div`
  height: 100%;
  overflow: hidden;
  transition: height 0.5s;
`

const AccordionHeading = styled.div`
  flex: 1;
`

const AccordionHeadingWrapper = styled.div`
  align-items: center;
  display: flex;

  :hover {
    cursor: pointer;
  }
`

const AccordionSectionWrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.alto};

  :first-child {
    border-top: 1px solid ${props => props.theme.alto};
  }
`

const AccordionWrapper = styled.div`
  ${props => props.wrapperStyles};
`

const ArrowIcon = styled.img`
  height: 16px;
  margin: 0 16px;
  transform: rotate(-90deg);
  transition: transform 0.5s;
`

export const AccordionSection = ({ content, heading, id }) => {
  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)
  const [heightCalculated, setHeightCalculated] = useState(false)

  const contentRef = useRef(null)

  const calculateHeight = () => {
    setHeightCalculated(false)
    setContentHeight(contentRef.current.getBoundingClientRect().height)
    setHeightCalculated(true)
  }

  useLayoutEffect(calculateHeight, [])

  useEffect(() => {
    window.addEventListener("resize", calculateHeight)
    return () => window.removeEventListener("resize", calculateHeight)
  })

  useEffect(() => setIsOpen(location.hash === `#${id}`), [])

  const handleClick = () => setIsOpen(!isOpen)

  return (
    <AccordionSectionWrapper id={id}>
      <AccordionHeadingWrapper onClick={handleClick}>
        <ArrowIcon
          src={arrowIcon}
          style={isOpen ? { transform: "rotate(0deg)" } : {}}
        />
        <AccordionHeading>{heading}</AccordionHeading>
      </AccordionHeadingWrapper>
      <AccordionContent
        ref={contentRef}
        style={
          isOpen
            ? heightCalculated
              ? { height: `${contentHeight}px`, visibility: "visible" }
              : { height: "auto" }
            : heightCalculated
            ? { height: "0px" }
            : { height: "auto", visibility: "hidden" }
        }
      >
        {content}
      </AccordionContent>
    </AccordionSectionWrapper>
  )
}

const Accordion = ({ children, wrapperStyles }) => (
  <AccordionWrapper wrapperStyles={wrapperStyles}>{children}</AccordionWrapper>
)

export default Accordion
